import React, { useRef, useState } from "react"
import { BiSend } from "react-icons/bi"
import Spinner from "./spinner"
import { Trans } from "gatsby-plugin-react-i18next"
import { StaticImage } from "gatsby-plugin-image"

export default function ContactForm({ page, landing }) {
  const API_MAIL_ADDRESS =
    "https://dayone-mail-api.netlify.app/.netlify/functions/api/mail"
  const [isSending, setIsSending] = useState(false)

  const fname = useRef()
  const lname = useRef()
  const email = useRef()
  const phone = useRef()
  const message = useRef()
  const [send, setSend] = useState(null)

  const formSubmit = event => {
    event.preventDefault()
    setIsSending(true)

    let data = {
      fname: fname.current.value,
      lname: lname.current.value,
      email: email.current.value,
      phone: phone.current.value,
      message: message.current.value,
      page,
    }

    console.log(data)
    //   axios
    //     // .post('https://www.api.day-one.gr/api/forma', data)
    //     .post(API_MAIL_ADDRESS, data)
    //     .then(res => {
    //       console.log(res);
    //       setIsSending(false)
    //       resetForm()
    //       setSend("success")
    //     })
    //     .catch(error => {
    //       setIsSending(false)
    //       setSend("unsuccess")
    //       console.log(error);
    //       console.log("message not sent")
    //     })
    // }

    fetch(API_MAIL_ADDRESS, {
      method: "POST",
      body: JSON.stringify(data),
    })
      .then(res => {
        console.log(res)
        setIsSending(false)
        resetForm()
        setSend("success")
      })
      .catch(error => {
        setIsSending(false)
        setSend("unsuccess")
        console.log(error)
        console.log("message not sent")
      })
  }

  // reset initital data
  const resetForm = () => {
    fname.current.value = ""
    lname.current.value = ""
    email.current.value = ""
    phone.current.value = ""
    message.current.value = ""
  }

  return (
    <div className="p-5 container text-dark">
      {landing === true && (
        <h1 className="text-center p-2 p-md-5 fw-normal">
          <Trans i18nKey="Don't know how your bussines can be benefited from uhappy?">
            Don't know how your bussines can be benefited from uhappy?
            {/* <StaticImage /> */}
          </Trans>
          <StaticImage src="../images/uhappy.svg" alt="Logo" className="ms-2" />
        </h1>
      )}
      <h2 className="text-center p-5 fw-bold">
        <Trans>Contact us today</Trans>
      </h2>

      <form className="row py-5" onSubmit={formSubmit}>
        <div className="row g-0">
          <div className="col-lg-3 col-md-6 p-3">
            <div className="form-floating">
              <input
                required
                placeholder="First Name"
                type="text"
                className="form-control border-dark border-1 shadow-sm"
                id="fname"
                ref={fname}
              />
              <label htmlFor="fname">
                <Trans>First Name</Trans>
              </label>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 p-3">
            <div className="form-floating">
              <input
                required
                placeholder="Last Name"
                type="text"
                className="form-control border-dark border-1 shadow-sm"
                id="lname"
                ref={lname}
              />
              <label htmlFor="lname">
                <Trans>Last Name</Trans>
              </label>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 p-3">
            <div className="form-floating">
              <input
                required
                placeholder="email"
                type="email"
                className="form-control border-dark border-1 shadow-sm"
                id="email"
                ref={email}
              />
              <label htmlFor="email">
                <Trans>Email</Trans>
              </label>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 p-3">
            <div className="form-floating">
              <input
                placeholder="tel"
                type="tel"
                className="form-control border-dark border-1 shadow-sm"
                id="tel"
                ref={phone}
              />
              <label htmlFor="tel">
                <Trans>Phone</Trans>
              </label>
            </div>
          </div>
        </div>

        <div className="col p-3">
          <div className="form-floating">
            <textarea
              required
              style={{ height: "200px" }}
              placeholder="Message"
              type="text"
              className="form-control border-dark border-1 shadow-sm"
              id="message"
              ref={message}
            />
            <label htmlFor="message">
              <Trans>Message</Trans>
            </label>
          </div>
        </div>
        <div className="p-3">
          <button
            disabled={isSending}
            type="submit"
            className="align-items-center justify-content-center d-flex
            w-100 btn btn-lg btn-dark my-3 hoverIconAnimation-s"
          >
            <Trans>Send</Trans>
            <div className="ms-2">
              {isSending ? (
                <Spinner
                  height="1rem"
                  width="1rem"
                  colorSpin="var(--bs-light)"
                />
              ) : (
                <BiSend />
              )}
            </div>
          </button>

          {send === "unsuccess" && (
            <div
              className="shadow bg-light border-danger border rounded
           mt-2 p-3 fw-bold text-danger text-center w-auto"
            >
              <Trans>Sending failed, please try again.</Trans>
            </div>
          )}

          {send === "success" && (
            <div
              className="shadow bg-light border-success border rounded
           mt-2 p-3 fw-bold text-success text-center w-auto"
            >
              <Trans>Email received, we will get back to you. Thank you!</Trans>
            </div>
          )}
        </div>
      </form>
    </div>
  )
}
