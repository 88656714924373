import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Trans } from "react-i18next"
import { Link } from "gatsby"

export default function HomeGrid1() {
  return (
    <div
      className="container text-center pt-5 "
      id="welcome"
      style={{ paddingBottom: "12rem" }}
    >
      <div style={{ padding: "10rem 0rem" }}>
        {/* <h1>
          <Trans>If it is not simple it is wrong</Trans>
        </h1> */}
        <h1 className="pb-5">
          <Trans>
            The easy, fast & powerful way to create meaningful surveys
          </Trans>
        </h1>
      </div>
      <div className="row">
        <div className="col-lg-6  py-3">
          <div
            className="border shadow-lg p-5 h-100 bg-white"
            style={{ borderRadius: "1rem" }}
          >
            <StaticImage
              src="../images/istockphoto-1225930804-612x612.jpg"
              quality={95}
              height={250}
              formats={["auto", "webp", "avif"]}
              alt="Market Research"
              style={{ objectFit: "contain" }}
            />
            <h3 className="text-center">
              <b>
                <Trans>Market Research</Trans>
              </b>
            </h3>
            <div className="text-center my-5">
              <h5 className="mt-5">
                <Trans>Simple, quick & efficient market research</Trans>
              </h5>
              <p className="mt-5">
                <Trans>
                  uhappy? gives you the tools to keep track of product quality
                  and acceptance by keeping in touch with your customers
                </Trans>
              </p>
            </div>
            <Link to="/marketresearch">
              <button className="btn btn-primary">
                <Trans>Learn more</Trans>
              </button>
            </Link>
          </div>
        </div>
        <div className="col-lg-6  py-3">
          <div
            className="border shadow-lg p-5 h-100 bg-white"
            style={{ borderRadius: "1rem" }}
          >
            <StaticImage
              src="../images/customer_expirience.jpg"
              quality={95}
              height={250}
              formats={["auto", "webp", "avif"]}
              alt="Customer Expirience"
              style={{ objectFit: "contain" }}
            />
            <h3 className="text-center">
              <b>
                <Trans>Customer Satisfaction</Trans>
              </b>
            </h3>
            <div className="text-center my-5">
              <h5 className="mt-5">
                <Trans>Immediate feedback upon purchase</Trans>
              </h5>
              <p className="mt-5">
                <Trans>
                  Connect with your customers, evaluate the data and implement
                  the necessary corrective actions
                </Trans>
              </p>
            </div>
            <Link to="/customersatisfaction">
              <button className="btn btn-primary">
                <Trans>Learn more</Trans>
              </button>
            </Link>
          </div>
        </div>
        <div className="col-lg-6 py-3">
          <div
            className="border shadow-lg p-5 h-100 bg-white"
            style={{ borderRadius: "1rem" }}
          >
            <StaticImage
              src="../images/employee_evaluation.jpg"
              quality={95}
              height={250}
              formats={["auto", "webp", "avif"]}
              alt="Employee Evaluation"
              style={{ objectFit: "contain" }}
            />
            <h3 className="text-center">
              <b>
                <Trans>Employee Evaluation</Trans>
              </b>
            </h3>
            <div className=" text-center my-5">
              <h5 className="mt-5">
                <Trans>Get insights on employee expertise</Trans>
              </h5>
              <p className="mt-5">
                <Trans>
                  Access staff competence directly from your clients, as well
                  as, in house evaluation
                </Trans>
              </p>
            </div>
            <Link to="/employeeevaluation">
              <button className="btn btn-primary">
                <Trans>Learn more</Trans>
              </button>
            </Link>
          </div>
        </div>
        <div className="col-lg-6 py-3">
          <div
            className="border shadow-lg p-5 h-100 bg-white"
            style={{ borderRadius: "1rem" }}
          >
            <StaticImage
              src="../images/employee_satisfaction.jpg"
              quality={95}
              height={250}
              formats={["auto", "webp", "avif"]}
              alt="Employee Evaluation"
              style={{ objectFit: "contain" }}
            />
            <h3 className="text-center">
              <b>
                <Trans>Employee Satisfaction</Trans>
              </b>
            </h3>
            <div className=" text-center my-5">
              <h5 className="mt-5">
                <Trans>Help your employees help you</Trans>
              </h5>
              <p className="mt-5">
                <Trans>
                  uhappy? can be applied internally to improve your business
                  work environment
                </Trans>
              </p>
            </div>
            <Link to="/employeesatisfaction">
              <button className="btn btn-primary">
                <Trans>Learn more</Trans>
              </button>
            </Link>
          </div>
        </div>

        <div className="col py-3">
          <div
            className="border shadow-lg p-5 h-100 bg-white"
            style={{ borderRadius: "1rem" }}
          >
            <StaticImage
              src="../images/istockphoto-1287822057-612x612.jpg"
              quality={95}
              height={250}
              formats={["auto", "webp", "avif"]}
              alt="Employee Evaluation"
              style={{ objectFit: "contain" }}
            />
            <h3 className="text-center">
              <b>
                <Trans>GDPR Compliance Tool</Trans>
              </b>
            </h3>
            <div className=" text-center my-5">
              <h5 className="mt-5">
                <Trans>Create lists of acceptable contacts</Trans>
              </h5>
              <p className="mt-5">
                <Trans>
                  GDPR contact information update service by accepting GDPR
                  rules
                </Trans>
              </p>
            </div>
            <Link to="/gdprservice">
              <button className="btn btn-primary">
                <Trans>Learn more</Trans>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
