import React from "react"
import { Trans } from "react-i18next"
import { Link } from "gatsby"
export default function HomeGrid4() {
  return (
    <div className="w-100 shadow">
      <div className="row g-0">
        <div className="col-sm-6 col-12 bg-dark text-light p-5 d-flex justify-content-center">
          <div className="py-5 text-center col-12 col-md-6">
            <h3>
              <Trans>For you</Trans>
            </h3>
            <h6 style={{ lineHeight: "1.75rem" }}>
              <Trans>For you texts</Trans>
            </h6>
            <Link to="/plans">
              <button className="btn btn-light btn-lg mt-5">
                <Trans>See all plans</Trans>
              </button>
            </Link>
          </div>
        </div>
        <div className="col-sm-6 col-12 bg-warning text-dark p-5 d-flex justify-content-center">
          <div className="py-5 text-center col-12 col-md-6 ">
            <h3 style={{ fontWeight: "800" }}>
              <Trans>For your organization</Trans>
            </h3>
            <h6 style={{ lineHeight: "1.75rem", fontWeight: "800" }}>
              <Trans>For your organization texts</Trans>
            </h6>
            <Link to="https://app.uhappy.gr/">
              <button className="btn btn-dark btn-lg mt-5">
                <Trans>Create Survey</Trans>
              </button>
            </Link>
          </div>
        </div>

        <div
          className="container text-center"
          style={{ padding: "12rem 0rem" }}
        >
          <div className="pt-5">
            <h1>
              <Trans>More than collecting feedback</Trans>
            </h1>
            <h4>
              <Trans>
                Explore uhappy? to drive change in your organization
              </Trans>
            </h4>
          </div>
          <Link to="/plans">
            <button className="btn btn-primary btn-lg mt-5">
              <Trans>Create Survey</Trans>
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}
