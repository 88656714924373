import React from "react"
import image from "../images/uh1.png"
import { Link } from "gatsby"
import { Trans } from "react-i18next"
import bgsvg from "../images/wave.svg"

export default function Hero() {
  return (
    <>
      <div
        className="w-100 bg-warning py-5 shadow"
        style={{
          backgroundImage: `url(${bgsvg})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center bottom",
          marginBottom: "-1px",
        }}
      >
        <div className="container  ">
          <div className="row align-items-center">
            <div className="col-lg-8 col-12 py-5">
              <img
                src={image}
                alt="uhappy screen?"
                className="w-100 rounded shadow-lg
                            border border-light border-1 wrapper imagePerspL"
              />
            </div>
            <div className="col-lg-4 col-12 py-5 ">
              <div className="text-light text-center w-100">
                <div
                  className="imagePerspR container shadow-lg py-4 px-4 border
                  border-light bg-dark border-1 d-flex flex-column "
                  style={{ borderRadius: "1rem" }}
                >
                  <div
                    className="text-warning display-1"
                    style={{ lineHeight: "0.5" }}
                  >
                    {" "}
                    ~
                  </div>
                  <h5 className="fw-light lh-base">
                    <Trans>
                      Design and create your own questionnaires and change the
                      image of your business.
                    </Trans>
                  </h5>
                  <div
                    className="text-warning display-1"
                    style={{ lineHeight: "0.5" }}
                  >
                    {" "}
                    ~
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Link to="#welcome" className="mouse_scroll mt-5 ">
          <div className="mouse shadow">
            <div className="wheel shadow"></div>
          </div>
          <div>
            <span className="m_scroll_arrows unu"></span>
            <span className="m_scroll_arrows doi"></span>
            <span className="m_scroll_arrows trei"></span>
          </div>
        </Link>
      </div>
    </>
  )
}
