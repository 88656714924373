import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Trans } from "react-i18next"
import { Link } from "gatsby"

export default function HomeGrid5() {
  return (
    <div className="w-100 bg-white">
      <div
        className="container d-flex p-5 align-content-center
        justify-content-around align-items-center flex-column"
      >
        <div
          className="container text-center"
          style={{ padding: "10rem 0rem" }}
        >
          <h1 className="display-4">
            <Trans>Set your goals</Trans>
          </h1>
          <h5>
            <Trans>We'll show you how to get there</Trans>
          </h5>
          <Link to="https://app.uhappy.gr">
            <button className="btn btn-primary btn-lg mt-5 shadow">
              <Trans>Create Survey</Trans>
            </button>
          </Link>
        </div>
      </div>

      <div className=" g-0 row">
        <div className=" h-75 container w-75 text-center">
          <StaticImage
            src="../images/footapple.png"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="Mobile View"
            style={{ objectFit: "contain" }}
          />
        </div>
        <div
          className="bg-warning row g-0 p-5 text-center shadow"
          style={{ minHeight: "8rem" }}
        ></div>
      </div>
    </div>
  )
}
