import * as React from "react"
// import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import HomeGrid1 from "../components/homegrid1"
// import HomeGrid2 from "../components/homegrid2"
import HomeGrid3 from "../components/homegrid3"
// import TestimonialSlider from "../components/testimonialslider"
import HomeGrid4 from "../components/homegrid4"
import HomeGrid5 from "../components/homegrid5"
import { useTranslation } from "react-i18next"
import { graphql } from "gatsby"
import ContactForm from "../components/contactform"

const IndexPage = () => {
  const { t } = useTranslation()

  return (
    <Layout hero={<Hero />}>
      <Seo title={t("Home")} />
      <div className="bg-light">
        <HomeGrid1 />
        {/* <HomeGrid2 /> */}
        <HomeGrid3 />
        <HomeGrid5 />
        {/* <TestimonialSlider /> */}
        <ContactForm page="uhappy? Landing" landing={true} />
        <HomeGrid4 />
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
