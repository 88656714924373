import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Trans } from "react-i18next"
import { Link } from "gatsby"

export default function HomeGrid3() {
  return (
    <div className="bg-dark text-light shadow">
      <div className="container ">
        <div className="row align-items-center">
          <div className="col-md-6 py-3">
            <div
              className="border shadow-lg p-5"
              style={{ borderRadius: "1rem" }}
            >
              <div>
                <h3 className="mt-5">
                  {" "}
                  <Trans>Know your customers</Trans>
                </h3>
                <p className="mt-5">
                  <Trans>
                    uhappy? surveys connect you with multiple sale points and
                    show you what your customers like and expect. It is a safe
                    direct way to measure brand awareness and test new concepts
                    to existing and potential clients
                  </Trans>
                </p>
              </div>
              <Link to="https://app.uhappy.gr/">
                <button className="btn btn-warning mt-5">
                  <Trans>Create Survey</Trans>
                </button>
              </Link>
            </div>
          </div>
          <div className="col-md-6 pt-3">
            <div className="" style={{ borderRadius: "1rem" }}>
              <StaticImage
                src="../images/mobileholding.png"
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Mobile View"
                style={{ objectFit: "contain" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
